<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="container" xmlns="http://www.w3.org/1999/html">
  <div class="dialog-title" mat-dialog-title>
    <button class="close-button" (click)="closeDialog({ confirmed: false })" mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-icon *ngIf="data.icon" [class]="data.icon" alt="available icon">{{ data.icon }}</mat-icon>
  <h2 *ngIf="data.title">
    {{ data.title }}
  </h2>

  <div class="content" [innerHTML]="data.content" mat-dialog-content></div>

  <mat-form-field class="col-12" *ngIf="data.countyList" appearance="outline">
    <mat-label>County</mat-label>
    <mat-select (selectionChange)="setCountyName($event.source.value?.name); setCounty($event.source.value?.id)">
      <mat-option [value]="" selected>Select County</mat-option>
      <mat-option *ngFor="let county of data.countyList as counties" [value]="county">
        {{ county.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="actions" *ngIf="showButtons" mat-dialog-actions>
    <button
      class="cancel-btn"
      *ngIf="data.showCancel && data.cancelLabel"
      (click)="onCancelClick(); $event.stopPropagation()"
      mat-stroked-button
    >
      {{ data.cancelLabel }}
    </button>
    <button
      class="confirm-btn"
      *ngIf="data.showConfirm"
      (click)="onConfirmClick(); $event.stopPropagation()"
      data-testid="modal-confirm-btn"
      mat-flat-button
      color="primary"
    >
      {{ data.confirmLabel }}
    </button>
  </div>
</div>
