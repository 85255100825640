import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AccordionModule, CommonDirectivesModule, CommonFooterModule, CommonToastModule } from '@dmv/common';
import { ActiveStepRequestsComponent } from './lib/component/active-step/active-step-requests/active-step-requests.component';
import { ActiveStepComponent } from './lib/component/active-step/active-step/active-step.component';
import { ApplicationChecklistComponent } from './lib/component/application-checklist/application-checklist.component';
import { CommonDashboardDocumentTypesModule } from './lib/component/dashboard-document-types/dashboard-document-types.module';
import { DashboardHeaderComponent } from './lib/component/dashboard-header/dashboard-header.component';
import { DocumentsReviewComponent } from './lib/component/documents-review/documents-review.component';
import { NavigationStepperComponent } from './lib/component/navigation-stepper/navigation-stepper.component';
import { SectionComponent } from './lib/component/section/section.component';
import { TerminalPageModule } from './lib/component/terminal-page/terminal-page.module';
import { FormErrorStateMatcherDirective } from './lib/utils/form-error-state-matcher.directive';
import { NgrxMatSelectViewAdapter } from './lib/utils/mat-select-view-adapter';
import { ValidationErrorMessagePipe } from './lib/utils/validation-error-message.pipe';
import { SectionV2Component } from './lib/component/section-v2/section-v2.component';
import { UploadListReviewComponent } from './lib/component/documents-review/upload-list-review/upload-list-review.component';

@NgModule({
  declarations: [
    ApplicationChecklistComponent,
    DashboardHeaderComponent,
    DocumentsReviewComponent,
    NavigationStepperComponent,
    SectionComponent,
    ActiveStepComponent,
    ActiveStepRequestsComponent,
    ValidationErrorMessagePipe,
    FormErrorStateMatcherDirective,
    NgrxMatSelectViewAdapter,
    SectionV2Component,
    UploadListReviewComponent,
  ],
  exports: [
    ApplicationChecklistComponent,
    CommonDashboardDocumentTypesModule,
    DashboardHeaderComponent,
    DocumentsReviewComponent,
    NavigationStepperComponent,
    SectionComponent,
    ActiveStepComponent,
    ActiveStepRequestsComponent,
    ValidationErrorMessagePipe,
    FormErrorStateMatcherDirective,
    NgrxMatSelectViewAdapter,
    CommonFooterModule,
    SectionV2Component,
    TerminalPageModule,
    UploadListReviewComponent,
  ],
  imports: [
    AccordionModule,
    CommonModule,
    CommonFooterModule,
    CommonToastModule,
    CommonDirectivesModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
  ],
})
export class PublicCommonModule {}

export { NavigationStepperComponent } from './lib/component/navigation-stepper/navigation-stepper.component';
