import { CountyModel } from '@dmv/common';
import { DocumentTypes, UploadItem } from './document-upload.model';
import { DocumentUpload } from './document-upload.type';
import { FileUploadDriverLicense } from './file-upload-staging';
import { LicenseClassChangeSectionId } from './license-class-change.model';
import { LicenseReciprocitySectionId } from './license-reciprocity.model';
import { OriginalNdidSectionId } from './original-ndid.model';
import { OriginalRegistrationSectionId } from './original-registration.model';
import { IdUpgradeSectionId } from './read-id-edl-dto.model';
import { SurveyWrapperState } from './survey-state.model';
import { SurveySectionType, UsedDocumentTypes } from './transaction.model';
import { VehicleInformation } from './vehicle-information.model';

export enum OriginalPermitSectionId {
  ADDITIONAL_FORM_INFORMATION = 'additional-form-information',
  PERSONAL_INFORMATION = 'personal-information',
  ID_TYPE = 'id-type',
  PROOF_OF_SOCIAL_SECURITY = 'proof-of-social-security',
  PROOF_OF_RESIDENCY = 'proof-of-residency',
  PROOF_OF_IDENTITY = 'proof-of-identity',
  PROOF_OF_LEGAL_NAME = 'proof-of-legal-name',
  PROOF_OF_BIRTH = 'proof-of-birth',
  MV_FOUR_FOUR = 'mv-four-four',
}

export type Status = 'complete' | 'not_started';
export type StatusResponse = 'Complete' | 'Incomplete';

export type StepType = 'document_upload' | 'form' | 'survey' | 'review';

export interface StepInfo {
  description: string;
  id: string;
  type: StepType;
}

export interface Section {
  active: boolean;
  description: string;
  checklistTitle?: string;
  documentTypes?: DocumentTypes[];
  id:
    | IdUpgradeSectionId
    | OriginalPermitSectionId
    | LicenseReciprocitySectionId
    | OriginalNdidSectionId
    | OriginalRegistrationSectionId
    | SurveySectionType;
  isDirty: boolean;
  parentSection?: string | undefined;
  prepopulateLoaded?: boolean;
  status: Status;
  steps: StepInfo[];
  submittedDocumentIds: number[];
  /**
   * Only usable for OR
   */
  usedDocumentTypes?: UsedDocumentTypes[];
}

export interface SectionDto {
  active: boolean;
  id:
    | IdUpgradeSectionId
    | LicenseClassChangeSectionId
    | LicenseReciprocitySectionId
    | OriginalNdidSectionId
    | OriginalRegistrationSectionId;
  parentSection?: string | undefined;
  possibleDocumentTypes: DocumentTypes[];
  status: 'Complete' | 'Incomplete';
  usedDocumentTypes: UsedDocumentTypes[];
}

export interface SectionInfo {
  active: boolean;
  description: string;
  parentSection?: string | undefined;
  sectionId: SurveySectionType;
  status: Status;
}

export interface SectionResponse {
  id: number;
  sectionStatus: Status;
}

export type OrderedSection =
  | { section: Section; type: 'standalone' }
  | { complete: boolean; parentSection: string; sections: Section[]; type: 'grouped' };

export interface IdUpgradeSectionStatusResponse {
  conditionalQuestions: ConditionalQuestions;
  sectionStatuses: IdUpgradeSectionStatus[];
}

export interface ConditionalQuestions {
  showSelectiveServiceQuestion: boolean;
  showTeensProgramQuestion: boolean;
  showLicensePermitEligibilityQuestion: boolean;
  showCdlQuestion: boolean;
}

export interface IdUpgradeSectionStatus {
  active: boolean;
  section: IdUpgradeSectionId;
  status: StatusResponse;
}

export type WorkflowType =
  | 'id-upgrade'
  | 'license-class-change'
  | 'original-registration'
  | 'original-permit'
  | 'license-reciprocity'
  | 'original-ndid';

export interface ProofSectionPayload {
  countyId?: any;
  officeId?: any;
  officeName?: any;
  damageExceeds75Percent?: boolean;
  didSurrenderPlates?: boolean;
  hasSecondParty?: boolean | undefined;
  isFeeEligibilityDocumentProvided?: boolean;
  isPayOnline?: boolean;
  nameChanged?: boolean;
  inheritanceDtfType?: string;
  isTaxCollectedByLeasingCompany?: boolean;
  twelveOrMoreLeasePaymentsRemaining?: boolean;
  meansOfAcquisition?: string;
  noProofOfResidency?: boolean;
  noSecondProofOfResidency?: boolean;
  nonPersonalUse?: string;
  otherNonPersonalUse?: string;
  providedDocuments: DocumentUpload[] | null;
  secondPartyName?: string | undefined;
  secondPartyCid?: string | undefined;
  sectionId: OriginalPermitSectionId | LicenseReciprocitySectionId | OriginalNdidSectionId | OriginalRegistrationSectionId;
  transactionId?: string;
}

export type VehicleInformationPayload = ProofSectionPayload | VehicleInformation;

export interface SectionDocumentTypes {
  sectionName:
    | IdUpgradeSectionId
    | LicenseReciprocitySectionId
    | OriginalNdidSectionId
    | OriginalPermitSectionId
    | OriginalRegistrationSectionId
    | SurveySectionType;
  documentTypes: DocumentTypes[];
}

export interface IdUpgradeSurveyState {
  activeStep: string | null;
  surveyWrapperState: SurveyWrapperState;
}

export interface ProofOfState extends IdUpgradeSurveyState {
  uploadItems: UploadItem[];
}

export interface CurrentLicenseState extends ProofOfState {
  appointmentCounty: CountyModel | null;
  documentPayload: DocumentUpload[] | null;
  uploadedDriverLicense: FileUploadDriverLicense | null;
}
