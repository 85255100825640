import { Injectable } from '@angular/core';
import {
  DocumentsReview,
  QuestionImpl,
  SectionContext,
  StepType,
  SurveyEvent,
  UploadDocumentDetail,
  UploadItem,
} from '@dmv/public/shared/http';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, pairwise, startWith } from 'rxjs/operators';
import * as SurveySectionActions from '../actions/survey-section.actions';
import { SurveySection } from '../reducers/survey-section.reducer';
import * as SurveySectionSelectors from '../selectors/survey-section.selectors';

@Injectable({
  providedIn: 'root',
})
export class SurveySectionFacade {
  public activeStep$ = this._store.pipe(select(SurveySectionSelectors.selectActiveStep));

  public selectAutoProceed$ = this._store.pipe(select(SurveySectionSelectors.selectActiveStep)).pipe(
    startWith('survey'),
    pairwise(),
    map(([prevValue, curValue]) => {
      return prevValue === 'survey' && curValue === 'review';
    }),
  );

  public selectSurveySectionQuestionIndex$ = this._store.pipe(select(SurveySectionSelectors.selectSurveySectionQuestionIndex));

  public selectSurveySectionQuestions$ = this._store.pipe(
    select(SurveySectionSelectors.selectSurveySectionQuestions),
    map(questions => questions.map(question => new QuestionImpl(question))),
  );

  public displayErrors$ = this._store.select(SurveySectionSelectors.selectDisplayErrors);

  public section$ = this._store.select(SurveySectionSelectors.selectSurveySection);

  public selectSectionDescription$ = this._store.select(SurveySectionSelectors.selectSectionDescription);

  public selectSectionId$ = this._store.select(SurveySectionSelectors.selectSectionId);

  public selectSectionReviewed$ = this._store.select(SurveySectionSelectors.selectSectionIsReviewed);

  public selectSectionUploadItems$ = this._store.select(SurveySectionSelectors.selectSectionUploadItems);

  public selectSurveySectionState$ = this._store.select(SurveySectionSelectors.selectSurveySectionState);
  public selectSurveyWrapperState$ = this._store.select(SurveySectionSelectors.selectSurveyWrapperState);

  constructor(private readonly _store: Store<SurveySection>) {}

  public checkDocChange(detail: UploadDocumentDetail): void {
    this._store.dispatch(SurveySectionActions.checkDocChange({ detail }));
  }

  public changeDocType(item: UploadItem): void {
    this._store.dispatch(SurveySectionActions.changeDocType({ item }));
  }

  public buildSurveySectionUploadItems(): void {
    return this._store.dispatch(SurveySectionActions.buildSurveySectionUploadItems());
  }

  public continueSurveySection(func?: (id: string) => SectionContext, submitAction?: Action): void {
    this._store.dispatch(SurveySectionActions.continueSurveySection({ func, submitAction }));
  }

  public markSectionReviewed(isReviewed: boolean): void {
    this._store.dispatch(SurveySectionActions.markSectionReviewed({ isReviewed }));
  }

  public previousQuestionIndex(): void {
    this._store.dispatch(SurveySectionActions.previousQuestionIndex());
  }

  public resetSections(): void {
    this._store.dispatch(SurveySectionActions.resetSections());
  }

  public setActiveStep(activeStep: StepType): void {
    this._store.dispatch(SurveySectionActions.setActiveStep({ activeStep }));
  }

  public setDisplayErrors(displayErrors: { display: boolean; errors: string[] }): void {
    this._store.dispatch(SurveySectionActions.displayErrors(displayErrors));
  }

  public selectSurveyAnswers() {
    return this._store.select(SurveySectionSelectors.selectSurveyAnswers);
  }

  public uploadDetailReuploaded(detail: UploadDocumentDetail): void {
    this._store.dispatch(SurveySectionActions.uploadDetailReuploaded({ detail }));
  }

  public selectFromOverallAnswersByQuestionId(
    questionId: string,
  ): Observable<{ answerId: string; answers: string[]; questionPrompt: string }> {
    return this._store.select(SurveySectionSelectors.selectFromOverallAnswersByQuestionId(questionId));
  }

  public sectionMarkDirty(isDirty: boolean): void {
    this._store.dispatch(SurveySectionActions.sectionMarkDirty({ isDirty }));
  }

  public selectSectionReviewObjects$(): Observable<DocumentsReview> {
    return this._store.select(SurveySectionSelectors.selectSectionReviewObjects);
  }

  public updateAnswers(answer: { answerId: string; answers: string[]; questionId: string; questionPrompt: string }): void {
    this._store.dispatch(SurveySectionActions.updateAnswers(answer));
  }

  public updateSurveyQuestionIndex(questionIndex: number) {
    this._store.dispatch(SurveySectionActions.updateSurveyQuestionIndex({ questionIndex }));
  }

  public updateSurveyStateRequiredDocumentIds(ids?: number[]) {
    this._store.dispatch(SurveySectionActions.updateSurveyStateRequiredDocumentIds({ ids }));
  }

  public updateSurveyStateQuestion(surveyEvent: SurveyEvent) {
    this._store.dispatch(SurveySectionActions.updateSurveyStateQuestion({ surveyEvent }));
  }

  public updateSurveyStateQuestionAnswer(surveyEvent: SurveyEvent) {
    this._store.dispatch(SurveySectionActions.updateSurveyStateQuestionAnswer({ surveyEvent }));
  }

  public updateSurveyWrapperState(surveyEvent: SurveyEvent) {
    this._store.dispatch(SurveySectionActions.updateSurveyWrapperState({ surveyEvent }));
  }

  public updateUploadItem(uploadItem: UploadItem, uploadsStatus?: 'completed' | 'fileUploaded') {
    this._store.dispatch(SurveySectionActions.updateUploadItem({ uploadItem, uploadsStatus }));
  }

  public selectSectionStepDescription$(step: number): Observable<string> {
    return this._store.select(SurveySectionSelectors.selectSectionStepDescription(step));
  }
}
