import { DocumentUpload } from './document-upload.type';
import { PermitType } from './permit-type.enum';

export enum IDType {
  NO_LICENSE = 'No License',
  STANDARD = 'Standard',
  REAL_ID = 'REAL ID',
  ENHANCED = 'Enhanced',
  NO_NON_DRIVER_ID = 'No NYS Non-Driver ID or License',
  STANDARD_NON_DRIVER_ID = 'Standard Non-Driver ID',
  REAL_NON_DRIVER_ID = 'REAL ID Non-Driver ID',
  ENHANCED_NON_DRIVER_ID = 'Enhanced Non-Driver ID',
}

export interface IdTypeRequest {
  permitType?: PermitType;
  existingIdType?: IDType;
  currentIdDocument?: DocumentUpload;
}
