//

enum DriverLicenseColorBrown {
  BRO = 'BRO',
  BRN = 'BRN',
  BR = 'BR',
}
export enum DriverLicenseCompliance {
  MATERIALLY_COMPLIANT = 'M',
  FULLY_COMPLIANT = 'F',
  NON_COMPLIANT = 'N',
}
export const DriverLicenseComplianceLabel = new Map<DriverLicenseCompliance, string>([
  [DriverLicenseCompliance.MATERIALLY_COMPLIANT, 'Materially-Compliant'],
  [DriverLicenseCompliance.FULLY_COMPLIANT, 'Fully-Compliant'],
  [DriverLicenseCompliance.NON_COMPLIANT, 'Non-Compliant'],
]);

enum DriverLicenseCountryCanada {
  CAN = 'CAN',
  CDN = 'CDN',
}

enum DriverLicenseCountryMexico {
  MEX = 'MEX',
  MX = 'MX',
}
enum DriverLicenseCountryUSA {
  USA = 'USA',
}

export enum DriverLicenseEndorsement {
  TANK = 'N',
  PASSENGER = 'P',
  SCHOOL_BUS = 'S',
  DOUBLES_TRIPLES = 'T',
  HAZARDOUS_MATERIAL = 'H',
  COMBINED_TANK_HAZARDOUS_MATERIAL = 'X',
  MOTORCYCLE = 'L',
}
export const DriverLicenseEndorsementLabel = new Map<DriverLicenseEndorsement, string>([
  [DriverLicenseEndorsement.TANK, 'Tank'],
  [DriverLicenseEndorsement.PASSENGER, 'Passenger'],
  [DriverLicenseEndorsement.SCHOOL_BUS, 'School Bus'],
  [DriverLicenseEndorsement.DOUBLES_TRIPLES, 'Doubles/Triples'],
  [DriverLicenseEndorsement.HAZARDOUS_MATERIAL, 'Hazardous Material'],
  [DriverLicenseEndorsement.COMBINED_TANK_HAZARDOUS_MATERIAL, 'Combined Tank/Hazardous Material'],
  [DriverLicenseEndorsement.MOTORCYCLE, 'Motorcycle'],
]);

enum DriverLicenseEyeColorBase {
  BLACK = 'BLK',
  BLUE = 'BLU',
  DICHROMATIC = 'DIC',
  GRAY = 'GRY',
  GREEN = 'GRN',
  HAZEL = 'HAZ',
  MAROON = 'MAR',
  PINK = 'PNK',
}

enum DriverLicenseHairColorBase {
  BALD = 'BAL',
  BLACK = 'BLK',
  BLOND = 'BLN',
  GRAY = 'GRY',
  RED_AUBURN = 'RED',
  SANDY = 'SDY',
  WHITE = 'WHI',
}

export enum DriverLicenseRace {
  ALASKAN_OR_AMERICAN_INDIAN = 'AI',
  ASIAN_OR_PACIFIC_ISLANDER = 'AP',
  BLACK = 'BK',
  HISPANIC_ORIGIN = 'H',
  NON_HISPANIC = 'O',
  UNKNOWN = 'U',
  WHITE = 'W',
}

export enum DriverLicenseRestriction {
  CORRECTIVE_LENSES_MUST_BE_WORN = 'B',
  MECHANICAL_AID = 'C',
  PROSTHETIC_AID = 'D',
  NO_MANUAL_TRANSMISSION_EQUIPPED_CMV = 'E',
  OUTSIDE_MIRROR = 'F',
  LIMIT_TO_DAYLIGHT_ONLY = 'G',
  LIMITED_TO_EMPLOYMENT = 'H',
  INTRASTATE_ONLY = 'K',
  NO_AIR_BRAKES_EQUIPPED_CMV = 'L',
  NO_CLASS_A_PASSENGER_VEHICLE = 'M',
  NO_CLASS_A_AND_CLASS_B_PASSENGER_VEHICLE = 'N',
  NO_TRACTOR_TRAILER_CMV = 'O',
  BREATH_ALCOHOL_IGNITION_INTERLOCK_DEVICE = 'T',
  MEDICAL_VARIANCE = 'V',
  LIMITED_OTHER = 'I',
  FARM_WAIVER = 'W',
  NO_FULL_AIR_BRAKE_EQUIPPED_CMV = 'Z',
  OTHER = 'J',
}
export const DriverLicenseRestrictionLabel = new Map<DriverLicenseRestriction, string>([
  [DriverLicenseRestriction.CORRECTIVE_LENSES_MUST_BE_WORN, 'Corrective lenses must be worn'],
  [DriverLicenseRestriction.MECHANICAL_AID, 'Mechanical Aid (special brakes, hand controls, or other adaptive devices)'],
  [DriverLicenseRestriction.PROSTHETIC_AID, 'Prosthetic Aid'],
  [DriverLicenseRestriction.NO_MANUAL_TRANSMISSION_EQUIPPED_CMV, 'No Manual Transmission Equipped CMV'],
  [DriverLicenseRestriction.OUTSIDE_MIRROR, 'Outside Mirror'],
  [DriverLicenseRestriction.LIMIT_TO_DAYLIGHT_ONLY, 'Limit to Daylight Only'],
  [DriverLicenseRestriction.LIMITED_TO_EMPLOYMENT, 'Limited to Employment'],
  [DriverLicenseRestriction.INTRASTATE_ONLY, 'Intrastate Only'],
  [DriverLicenseRestriction.NO_AIR_BRAKES_EQUIPPED_CMV, 'No Air Brakes Equipped CMV'],
  [DriverLicenseRestriction.NO_CLASS_A_PASSENGER_VEHICLE, 'No Class A Passenger Vehicle'],
  [DriverLicenseRestriction.NO_CLASS_A_AND_CLASS_B_PASSENGER_VEHICLE, 'No Class A and Class B Passenger Vehicle'],
  [DriverLicenseRestriction.NO_TRACTOR_TRAILER_CMV, 'No Tractor-Trailer CMV'],
  [DriverLicenseRestriction.BREATH_ALCOHOL_IGNITION_INTERLOCK_DEVICE, 'Breath Alcohol Ignition Interlock Device'],
  [DriverLicenseRestriction.MEDICAL_VARIANCE, 'Medical Variance'],
  [DriverLicenseRestriction.LIMITED_OTHER, 'Limited - Other'],
  [DriverLicenseRestriction.FARM_WAIVER, 'Farm Waiver'],
  [DriverLicenseRestriction.NO_FULL_AIR_BRAKE_EQUIPPED_CMV, 'No Full Air Brake Equipped CMV'],
  [DriverLicenseRestriction.OTHER, 'Other'],
]);

export enum DriverLicenseSex {
  MALE = 1,
  FEMALE = 2,
  NOT_SPECIFIED = 9,
}

export const DriverLicenseSexLabel = new Map<DriverLicenseSex, string>([
  [DriverLicenseSex.MALE, 'M'],
  [DriverLicenseSex.FEMALE, 'F'],
  [DriverLicenseSex.NOT_SPECIFIED, 'N'],
]);

export const DriverLicenseCountries = {
  ...DriverLicenseCountryCanada,
  ...DriverLicenseCountryMexico,
  ...DriverLicenseCountryUSA,
};
export const DriverLicenseEyeColors = {
  ...DriverLicenseColorBrown,
  ...DriverLicenseEyeColorBase,
};

export const DriverLicenseHairColors = {
  ...DriverLicenseColorBrown,
  ...DriverLicenseHairColorBase,
};

export interface FileUploadStaging {
  docAiProcessors?: string[];
  docAiResultsId?: string;
  stagedId: number;
  side: string | null;
}

export interface DocAiResponse {
  data: DocAiResponseData;
  processor: string;
  resultsId: string;
}

export interface DocAiResponseData {
  qualityScore: DocAIQualityScore;
  expectedDocumentType: boolean;
  documentTypeName: string;
  documentTypeId: number;
  topValidationError: DocAIValidationErrorData | null;
}

export interface DocAIValidationErrorData {
  errorMessage: string | null;
}

export interface DocAIClassificationData {
  isExpectedDocumentType: boolean;
  classifiedDocumentTypeName: string;
  classifiedDocumentTypeId: number;
  expectedDocumentTypeName: string;
}

export interface DocAIExtractionData {
  validationError: string;
}

export type DocAIQualityScore = 'PASS' | 'FAIL';

export interface DocAiAlert {
  body?: string;
  icon?: string;
  title?: string;
}

export interface DocAiNotificationError {
  bodyText?: string;
  bodyClass?: string;
  headerText?: string;
  headerClass?: string;
  iconClass?: string;
  notificationClass?: string;
  showClose: boolean;
}

export type DriverLicenseCountry = keyof typeof DriverLicenseCountries;
export type DriverLicenseEyeColor = keyof typeof DriverLicenseEyeColors;
export type DriverLicenseHairColor = keyof typeof DriverLicenseHairColors;

export interface FileUploadDriverLicense extends FileUploadStaging {
  identificationCard: {
    aamvaVersion: string;
    issuerIdentificationNumber: number;
    jurisdictionVersionNumber: number;
    customerIdNumber: string;
    documentDiscriminator: string;
    limitedDurationDocument: boolean;
    inventoryControlNumber: string;
    subfileType: string;
    name: {
      first: string;
      middle: string;
      last: string;
      suffix: string;
      firstTruncated: boolean;
      middleTruncated: boolean;
      lastTruncated: boolean;
      aliasFirst: string;
      aliasLast: string;
      aliasSuffix: string;
    };
    address: {
      streetLine1: string;
      streetLine2: string;
      city: string;
      jurisdictionCode: string;
      postalCode: string;
      country: DriverLicenseCountry;
    };
    dateOfBirth: string;
    expirationDate: string;
    issueDate: string;
    revisionDate: string;
    sex: DriverLicenseSex;
    eyeColor: DriverLicenseEyeColor;
    hairColor: DriverLicenseHairColor;
    height: {
      feet: number;
      inches: number;
    };
    weight: {
      pounds: number;
    };
    race: DriverLicenseRace;
    complianceType: DriverLicenseCompliance;
    jurisdiction: {
      vehicleClass: string;
      standardVehicleClass: string;
      restrictionCodes: string;
      restrictions: DriverLicenseRestriction[];
      endorsementCodes: string;
      endorsements: DriverLicenseEndorsement;
    };
    hazmatEndorsementExpirationDate: string;
  };
}
